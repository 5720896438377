import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Copyright from '@ropto/ui-copyright';

import './styles.scss';

const Footer = ({ location }) => (
  <footer className="footer">
    <ul className="footer__links footer__links--copyright">
      <Copyright productName="The Claims Guys Legal" />
    </ul>
    <ul className="footer__links">
      <li><Link to={`/privacy-policy/${location.search}`}>Privacy Policy</Link></li>
    </ul>
  </footer>
);

Footer.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Footer;
