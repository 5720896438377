import React from 'react';
import logo from '../../images/tcg-logo.png';

import './styles.scss';

const Header = () => (
  <header className="header">
    <img src={logo} alt="The Claim Guy Legal" />
  </header>
);

export default Header;
